body {
  padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px)
    env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
  height: 100vh;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 0 0 4px 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
  cursor: pointer;
}

/* ------------------------- */
